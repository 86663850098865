import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface UserManagementState {
  pageNumber : number;
  search:string;
  pageSize:number;
  loader:boolean;
  pageOrder:string;
  filter:any
}
export interface VendorMangement{
   pageSize:number;
   search:string;
}
export const initialState: UserManagementState = {
    pageNumber: 1,
    search : "",
    pageSize:25,
    loader:false,
    pageOrder:"createdAt dsc",
    filter:{
      vendorsId:[],
      vendorsCode:[],
      pageSize:25,
      search:""
    }
   
};
interface userFilter{
  name:string;
  value : string[];
}
const vendorParams = {
  pageSize: 25,
  vendorsId:[],
  vendorsCode:[],
  search:""
};
const userManagementSlice = createSlice({
  name: "userManagement",
  initialState,
  reducers: {
    setPageNumber : (state,action:PayloadAction<number>)=>{
        state.pageNumber =  action.payload;
    },
    search : (state,action:PayloadAction<string>)=>{
      state.pageNumber = 1;
      state.search =  action.payload;
  },
  setPageSize: (state, action: PayloadAction<number>) => {
    state.pageSize = action.payload
  },
  searchVendors : (state,action:PayloadAction<string>)=>{
      state.filter.search = action.payload
  },
  setCategoryFilter : (state,action:PayloadAction<userFilter>)=>{
    state.pageNumber = 1;
    state.filter[action.payload.name] = [...action.payload.value];
    
  },
  setPageOrder:(state,action)=>{
    state.pageOrder = action.payload;
},
  setLoader  : (state,action:PayloadAction<boolean>)=>{
      state.loader = action.payload;
  },
  clearParticipantState : ()=>{
     return initialState
   }
  },  
});
const { actions, reducer } = userManagementSlice;

export const { setPageNumber,searchVendors,setLoader,setPageOrder ,search,setPageSize,clearParticipantState,setCategoryFilter} = actions;
export default reducer;
