import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ParticipantManagementState {
  pageNumber : number;
  search:string;
  pageSize:number;
  loader:boolean;
  pageOrder:string;
  filter:VendorMangement
}
export interface VendorMangement{
   vendors:string[];
   pageSize:number;
   search:string;
}
export const initialState: ParticipantManagementState = {
    pageNumber: 1,
    search : "",
    pageSize:25,
    loader:false,
    pageOrder:"",
    filter:{
      vendors:[],
      pageSize:25,
      search:""
    }
   
};
interface vendorFilter{
  name:string;
  value : string[];
}
const participantManagementSlice = createSlice({
  name: "participant",
  initialState,
  reducers: {
    setPageNumber : (state,action:PayloadAction<number>)=>{
        state.pageNumber =  action.payload;
    },
    searchParticipant : (state,action:PayloadAction<string>)=>{
      state.pageNumber=1;
      state.search =  action.payload;
  },
  setPageSize: (state, action: PayloadAction<number>) => {
    state.pageSize = action.payload
  },
  searchVendors : (state,action:PayloadAction<string>)=>{
      state.filter.search = action.payload
  },
  setCategoryFilter : (state,action:PayloadAction<vendorFilter>)=>{
    state.pageNumber = 1;
    state.filter.vendors = [...action.payload.value];
    
  },
  setPageOrder:(state,action)=>{
    state.pageOrder = action.payload;
},
  setLoader  : (state,action:PayloadAction<boolean>)=>{
      state.loader = action.payload;
  },
  clearParticipantState : ()=>{
     return initialState
   }
  },  
});
const { actions, reducer } = participantManagementSlice;

export const { setPageNumber,searchVendors,setLoader,setPageOrder ,searchParticipant,setPageSize,clearParticipantState,setCategoryFilter} = actions;
export default reducer;
